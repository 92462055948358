import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="Cristian Serrano" src="/static/images/avatar/1.jpg" />,
    name: 'Cristian Serrano',
    occupation: 'Software Engineer',
    testimonial:
      "I’m constantly developing software. "+
      "Usually I use Unity3D, Autodeks Maya, and design tools like Gravit Designer for create fantastic 3D enviroments for hobbie. "+
      "In my last job I was working for big e-commerce like ShakeShack, CK, Panda Express, Petco for the region of Latin America. Recently I've been working with ChatGPT and Open IA."
  },
  {
    avatar: <Avatar alt="Emmanuel Canto Vazques" src="/static/images/avatar/2.jpg" />,
    name: 'Emmanuel Canto Vazques',
    occupation: 'Software Developer',
    testimonial:
      "Being passionate and eager to contribute using my experience "+
      "and knowledge, I have 3 years of experience working as a"+
      "freelancer and 2 working professionally. "+
      "I am committed to following the best coding practices to"+
      "produce and deliver maintainable and readable code, as well"+
      "as following Figma designs to the letter to deliver to end users"+
      "delightful experiences.",
  },
  {
    avatar: <Avatar alt="José Enrique Sevilla Larrea" src="/static/images/avatar/3.jpg" />,
    name: 'José Enrique Sevilla Larrea',
    occupation: 'DevOps Engineer',
    testimonial:
      "I am a web developer with solid knowledge of SQL databases and part of NoSql using MongoDB and Firebase. "+ 
      "I have used frameworks such as Laravel and Nodejs for the backend, on the frontend Angular and mainly Vue JS. "+
      "I am constantly looking to expand my capabilities and support my colleagues with my skills.",
  },
];

const whiteLogos = [
  'https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/svg%2Fios_label.svg?alt=media&token=879ece72-1c37-4da2-8825-4caf11b3c655',
  'https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/svg%2Freact_label.svg?alt=media&token=976b2173-6966-4bcb-a1a2-ccdd1d477b50',
  'https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/svg%2Fphp_label.svg?alt=media&token=ce0c38e5-d50d-4f88-9c74-d1dd48e1d73b',

];

const darkLogos = [
  'https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/svg%2Fios_label.svg?alt=media&token=879ece72-1c37-4da2-8825-4caf11b3c655',
  'https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/svg%2Freact_label.svg?alt=media&token=976b2173-6966-4bcb-a1a2-ccdd1d477b50',
  'https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/svg%2Fphp_label.svg?alt=media&token=ce0c38e5-d50d-4f88-9c74-d1dd48e1d73b',

];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Our Team
        </Typography>
        <Typography variant="body1" color="text.secondary">
          At Leidenfrost, we are a dynamic and talented group of developers, 
          designers, and innovators committed to bringing your ideas to life. 
          With years of collective experience and a passion for pushing the boundaries of technology, 
          we specialize in crafting bespoke solutions that drive success and transform industries.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
               <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
             
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
