import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import TerminalIcon from '@mui/icons-material/Terminal';

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'UI-UX',
    promotional:"🚀 Elevate Your Digital Experience with Our Premier UI/UX Design Services! 🚀",
    description:
      "In today’s fast-paced digital world, the first impression is everything. Your website or app is not just a platform; it's the gateway to your brand's story. At Leidenfrost, we understand the power of an exceptional user interface (UI) and user experience (UX). That's why we're dedicated to transforming your digital platforms into captivating, intuitive, and seamless experiences that engage and delight your users at every touchpoint.",
    imageLight: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_1.jpg?alt=media&token=70e0792f-0418-40e1-ac6d-b10ec9db604f")',
    imageDark: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_1.jpg?alt=media&token=70e0792f-0418-40e1-ac6d-b10ec9db604f")',
  },
  {
    icon: <SportsEsportsIcon />,
    title: 'Game Development',
    promotional:"Your game deserves to be played, enjoyed, and remembered. With Leidenfrost, you're not just hiring a service; you're partnering with a team that shares your dream. We're here to ensure your game makes an unforgettable impact in the gaming world",
    description:
      "Are you ready to turn your game idea into a stunning reality? At Leidenfrost, we're passionate about bringing stories to life through immersive gameplay, breathtaking graphics, and innovative mechanics. Whether you're aiming to captivate casual gamers or hardcore enthusiasts, our team is equipped to transform your vision into a playable masterpiece.",
    imageLight: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_2.jpg?alt=media&token=d544b0f2-6334-4a5e-a3b6-88d5272545fe")',
    imageDark: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_2.jpg?alt=media&token=d544b0f2-6334-4a5e-a3b6-88d5272545fe")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Mobile App Development',
    promotional:"Let's collaborate to create a mobile app that not only meets but surpasses your expectations. With us, your app is destined for the top charts. Our commitment to innovation, quality, and client satisfaction makes us the perfect partner for your mobile app development needs.",
    description:
      "In a world where mobile is king, your business deserves an app that stands out. At Leidenfrost, we specialize in turning innovative ideas into stunning, user-friendly mobile applications that capture hearts and minds. Whether you're a startup aiming to disrupt the market or an established brand looking to enhance user engagement, our team is your gateway to mobile excellence.",
    imageLight: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_3.jpg?alt=media&token=f43a1dd2-9f18-4145-8a68-c0316e761252")',
    imageDark: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_3.jpg?alt=media&token=f43a1dd2-9f18-4145-8a68-c0316e761252")',
  },
  {
    icon: <ViewInArIcon />,
    title: 'Virtual Reality',
    promotional:"",
    description:
      "Dive into the world of limitless possibilities with us, where we bring your wildest dreams to life through the power of Virtual Reality (VR). Whether you're looking to revolutionize your business, create an immersive game, educate through engagement, or host virtual events, our VR solutions are tailored to transcend the boundaries of the physical world and unlock a new realm of experience.",
    imageLight: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_4.jpg?alt=media&token=75ecb7f6-b36d-4b4a-b07b-07978f5a4074")',
    imageDark: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_4.jpg?alt=media&token=75ecb7f6-b36d-4b4a-b07b-07978f5a4074")',
  },
  {
    icon: <ArchitectureIcon />,
    title: 'Rendering',
    promotional:"",
    description:
      "At Leidenfrost, we bring your visions to life with stunning realism and precision. Our state-of-the-art rendering services are designed to transform your ideas into breathtaking visuals that captivate and inspire. Whether you're in architecture, interior design, real estate, or product development, our expert team is equipped to deliver high-quality renderings that exceed your expectations.",
    imageLight: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_5.jpg?alt=media&token=7014fe62-8d6f-4dde-a634-bd7ebbe03044")',
    imageDark: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/landing_5.jpg?alt=media&token=7014fe62-8d6f-4dde-a634-bd7ebbe03044")',
  },
  {
    icon: <TerminalIcon />,
    title: 'Software Development',
    promotional:"🚀 Transform Your Business with Custom Software Solutions from [Your Company Name] 🚀",
    description:
      "In the digital age, innovative software isn't just an advantage—it's a necessity. Leidenfrost specializes in crafting custom software solutions that drive growth, efficiency, and competitiveness for businesses of all sizes. From startups to Fortune 500 companies, we turn complex challenges into easy-to-use solutions.",
    imageLight: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/software_development_service.webp?alt=media&token=18d97306-ec37-43e9-916c-f0766a699907")',
    imageDark: 'url("https://firebasestorage.googleapis.com/v0/b/leidenfrost-web.appspot.com/o/software_development_service.webp?alt=media&token=18d97306-ec37-43e9-916c-f0766a699907")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
            Service and Products
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              We can develop any of the follow bellow components
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2, pt:2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                variant='outlined'
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  boxShadow: selectedItemIndex === index ? '0px 0px 10px 2px #55a6f6, 1px 1.5px 0px 0px #fff, 2px 2px 10px 0px #55a6f6' : undefined,
                  backgroundColor:
                    selectedItemIndex === index ? '#55a6f647' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>

                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}