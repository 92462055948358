import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faq=[
  {
    question:"How experienced is your team?",
    answer:"We provide a comprehensive range of software development services, including but not limited to web development, mobile app development (iOS and Android), custom software solutions, e-commerce development, UI/UX design, and software consulting.",
    expanded:"panel1",
    ariaControls:"panel1d-content",
    id:"panel1d-header"
  },
  {
    question:"What is your pricing structure for software development projects?",
    answer:"Our pricing is tailored to the specific requirements and scope of each project. We offer flexible pricing models, including fixed-price, time and materials, and dedicated team arrangements. Our team will work closely with you to understand your project needs and provide a transparent and competitive pricing estimate.",
    expanded:"panel2",
    ariaControls:"panel2d-content",
    id:"panel2d-header"
  },
  {
    question:"How long does it typically take to complete a software development project?",
    answer:"The duration of a project varies depending on its complexity, scope, and requirements. We conduct thorough project scoping and planning to provide realistic timelines. Factors such as development iterations, client feedback cycles, and third-party integrations may also influence the project timeline. Rest assured, we strive to deliver high-quality solutions within agreed-upon deadlines.",
    expanded:"panel3",
    ariaControls:"panel3d-content",
    id:"panel3d-header"
  },
  {
    question:"Can you accommodate specific technology preferences or requirements?",
    answer:"Absolutely! We understand that each project may have unique technological requirements or preferences. Our team is proficient in a wide array of programming languages, frameworks, and technologies, and we are more than capable of accommodating your specific technological needs to deliver a tailored solution.",
    expanded:"panel4",
    ariaControls:"panel4d-content",
    id:"panel4d-header"
  },
  {
    question:"Do you provide maintenance and support services after the project is launched?",
    answer:"Yes, we offer post-launch maintenance and support services to ensure the continued functionality and performance of your software solution. Our support team is available to address any technical issues, provide troubleshooting assistance, and implement updates or enhancements as needed. We also offer service level agreements (SLAs) for peace of mind.",
    expanded:"panel5",
    ariaControls:"panel5d-content",
    id:"panel5d-header"
  },
  {
    question:"What is your process for handling support and maintenance after the project is completed?",
    answer:"We offer ongoing support and maintenance services to ensure the smooth operation and longevity of your software solution. Our support team is readily available to address any issues, provide technical assistance, and implement updates or enhancements as needed. We also offer service level agreements (SLAs) for guaranteed response times and support availability.",
    expanded:"panel6",
    ariaControls:"panel6d-content",
    id:"panel6d-header"
  },
  {
    question:"What is your pricing structure for software development projects?",
    answer:" Our pricing is tailored to the specific requirements and scope of each project. We offer flexible pricing models, including fixed-price, time and materials, and dedicated team arrangements. Our team will work closely with you to understand your project needs and provide a transparent and competitive pricing estimate.",
    expanded:"panel7",
    ariaControls:"panel7d-content",
    id:"panel7d-header"
  },
]

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        {faq?.map(item => (
           <Accordion
           expanded={expanded === item.expanded}
           onChange={handleChange(item.expanded)}
         >
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls={item.ariaControls}
             id={item.id}
           >
             <Typography component="h3" variant="subtitle2">
               {item.question}
             </Typography>
           </AccordionSummary>
           <AccordionDetails>
             <Typography
               variant="body2"
               gutterBottom
               sx={{ maxWidth: { sm: '100%', md: '70%' } }}
             >
               {item.answer}
               <Link> <br></br><br></br>support@leidenfrost.io </Link>
               or calling our toll-free number. We&apos;re here to assist you
               promptly.
             </Typography>
           </AccordionDetails>
        </Accordion>
  ))}
       
      

      </Box>
    </Container>
  );
}